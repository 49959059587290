import React, { useEffect, useState } from 'react';
import './Body.css';

import GuitarsList from './GuitarsList';

function Body() {
  return (
    <div className="body">
      <GuitarsList/>
      <img src={"/seal.png"} alt="Logo" height={300} />
      <p style={{"fontFamily":"cursive", fontSize:90, color:"white"}}>Coming Soon</p>
    </div>
  );
}

export default Body;