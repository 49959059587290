import { initializeApp } from 'firebase/app';
import {getFunctions, connectFunctionsEmulator, httpsCallable} from "firebase/functions"
import { getFirestore } from 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAvkG9Gpq-d1kPhpNQfoXvl5eA_1MM9xcQ",
    authDomain: "luxeguitars.firebaseapp.com",
    projectId: "luxeguitars",
    storageBucket: "luxeguitars.appspot.com",
    messagingSenderId: "994499165315",
    appId: "1:994499165315:web:e8d0097383e132e4b76fb6",
    measurementId: "G-EV6QYRH8Z8"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

if (process.env.NODE_ENV === 'development') {
    const functions = getFunctions(app);
    connectFunctionsEmulator(functions, "localhost", 5001);
}

export const createGuitar = async (guitarData) => {
  const functions = getFunctions(app);
  const createGuitarFunction = httpsCallable(functions, 'createGuitar');
  
  try {
    const result = await createGuitarFunction(guitarData);
    console.log('Guitar created with ID:', result.data.id);
  } catch (error) {
    console.error('Error creating guitar:', error);
  }
};
