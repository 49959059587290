import React from 'react';
import './Footer.css';

function Footer() {
  const links = [
    ['About Us', 'Contact'],
    ['Shipping', 'Returns', 'Order Status'],
    ['Facebook', 'Twitter', 'Instagram']
  ];

  return (
    <footer className="footer">
      {links.map((column, index) => (
        <div key={index} className="footer-column">
          {column.map((link, linkIndex) => (
            <a key={linkIndex} href="#">{link}</a>
          ))}
        </div>
      ))}
    </footer>
  );
}

export default Footer;