import React from 'react';
import './Header.css';
import { FaShoppingCart } from 'react-icons/fa';
import Logo from './Logo';

function Header() {
  return (
    <header className="header">
      <Logo />
      <nav className="nav">
        <button>guitars</button>
        <button>shop</button>
        <button>history</button>
      </nav>
      <div className="basket">
        <FaShoppingCart />
      </div>
    </header>
  );
}

export default Header;