import React, {useEffect, useState} from "react"
import { db } from '../services';
import { onSnapshot, collection } from 'firebase/firestore';
import "./GuitarsList.css"

const GuitarsList = (props) =>{ 
    const [itemsList, setItemsList] = useState([])
    useEffect(() => {
        const unsub = onSnapshot(
          collection(db, "guitars"), 
          { includeMetadataChanges: false }, 
          (collection) => {
            setItemsList(collection.docs.map((doc) => doc.data()))
          });
    
          return () => {
            unsub()
          }
      },[])
    return <div className="grid-container">
    <div className="grid">
      {itemsList.map(item => (
        <div key={item.id} className="item">
          <img src={item.image} alt={item.description} />
          <p>{item.name}</p>
        </div>
      ))}
    </div>
  </div>
}

export default GuitarsList;